import React, { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
/* AntD */
import { Button, Spin, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
/* Components */
import {
  AntdFormInputField,
  AntdFormSelectField,
  AntdSearch,
  CustomColumnType,
  CustomGridAntd,
  ErroredLayout,
  Layout,
} from "../../components";
/* Services and interfaces */
import {
  getUsers,
  UserInterface,
  UserParamsInterface,
  UserResponseInterface,
} from "../../services/user";
/* Utils */
import { usersTranslations, commonTranslations } from "../../helpers/translations";
import {
  notify,
  operationError,
} from "../../services/notification-wrapper";
import moment from "moment";
import { useSafeEffect } from "../../helpers/hooks";
import { getDropdownByKey, MasterDataResponse } from "../../services/masterData";
import { mapToSelectData } from "../../helpers";
import { SorterResult } from "antd/lib/table/interface";

const Users = withErrorBoundary(() => {

  const navigate = useNavigate();
  const usersTrans = usersTranslations;
  /* View data */
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [companies, setCompanies] = useState([]);
  /* Filters */
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [params, setParams] = useState<UserParamsInterface>({});
  const [sort, setSort] = useState<any>(null);
  /* Loadings */
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    fetchUsersList();
  }, [params])

  useSafeEffect(
    [],
    () => {
      setLoading(true);
      const supplierPromise = getDropdownByKey("supplier");
      const partnerPromise = getDropdownByKey("partner");
      const customerPromise = getDropdownByKey("customer");
      return Promise.all([supplierPromise, customerPromise, partnerPromise]);
    },
    (data: [MasterDataResponse, MasterDataResponse, MasterDataResponse]) => {
      if (data && data.length > 1) {
        const [_suppliers, _customer, _partner] = data;
        setCompanies(mapToSelectData([..._suppliers.values, ..._customer.values, ..._partner.values, "Ferservizi"]))
      }
    }
  );

  const fetchUsersList = async () => {
    try {
      setTableLoading(true);
      const usersResponse: UserResponseInterface = await getUsers(params);
      setUsers(usersResponse.users);
      setTotalRows(usersResponse.totalrows || 0);
    } catch (e) {
      console.log("Error fetching data? ", e);
      notify(operationError());
    } finally {
      setTableLoading(false);
      setLoading(false);
    }
  };

  const goToEdit = (id?: number) => {
    let url = "/configuration/users/edit";
    if (id) {
      url += `/${id}`;
    }
    navigate(url);
  };

  const columns: CustomColumnType[] = [
    {
      title() {
        return usersTrans.tableHeadings.name;
      },
      dataIndex: "firstNameAndLastName",
      key: "firstNameAndLastName",
      name: usersTrans.tableHeadings.name,
      sorter: (a) => false,
      render(name, record) {
        return `${record.lastName} ${record.firstName}`
      },
    },
    {
      title() {
        return usersTrans.tableHeadings.CF;
      },
      dataIndex: "CF",
      key: "CF",
      name: usersTrans.tableHeadings.CF,
      sorter: (a) => false,
    },
    {
      title() {
        return usersTrans.tableHeadings.company;
      },
      dataIndex: "company",
      key: "company",
      name: usersTrans.tableHeadings.company,
      sorter: (a) => false,
    },
    {
      title() {
        return usersTrans.tableHeadings.username;
      },
      dataIndex: "username",
      key: "username",
      name: usersTrans.tableHeadings.username,
      sorter: (a) => false,
    },
    {
      title() {
        return usersTrans.tableHeadings.email;
      },
      dataIndex: "email",
      key: "email",
      name: usersTrans.tableHeadings.email,
      sorter: (a) => false,
    },
    {
      title() {
        return usersTrans.tableHeadings.role;
      },
      dataIndex: "roles_id",
      key: "roles_id",
      name: usersTrans.tableHeadings.role,
      sorter: (a) => false,
      render(name, record) {
        return record.role?.label
      },
    },
    {
      title() {
        return usersTrans.tableHeadings.isActive;
      },
      dataIndex: "isActive",
      key: "isActive",
      name: usersTrans.tableHeadings.isActive,
      sorter: (a) => false,
      render(name, record) {
        return record.isActive ? commonTranslations.buttons.yes : commonTranslations.buttons.no
      },
    },
    {
      title() {
        return usersTrans.tableHeadings.expireDate;
      },
      dataIndex: "expireDate",
      key: "expireDate",
      name: usersTrans.tableHeadings.expireDate,
      render(name, record) {
        return record.expireDate ? moment(record.expireDate).format("DD/MM/yyyy") : "";
      },
      sorter: (a) => false
    },
    ...[
      {
        title() {
          return <div>
            <span>{usersTrans.tableHeadings.actions}</span>
          </div>
        },
        dataIndex: "actions",
        key: "actions",
        name: "",
        align: "center",
        render: (id: number) => (
          <div className="d-flex w-100 justify-content-center">
            <Tooltip title={commonTranslations.tooltips.edit}>
              <Button type="link" onClick={() => goToEdit(id)}>
                <EditOutlined style={{ fontSize: "125%" }} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ]
  ];

  const dataSource = users.map((user: UserInterface, index: number) => ({ ...user, key: index, actions: user.id }));

  return (
    <Layout title={usersTrans.title} setIsEditing={goToEdit}>
      <>
        {showFilters && (
          <AntdSearch
            name="usersSearch"
            onSearch={(searchParams) => {
              setCurrentPage(1);
              setParams((currentParams) => ({ ...currentParams, firstNameAndLastName: null, username: null, company: null, ...searchParams, offset: 0 }));
            }}
          >
            <AntdFormInputField
              name="firstNameAndLastName"
              placeholder={usersTrans.search.placeholders.name}
              label={usersTrans.search.labels.name}
            />

            <AntdFormInputField
              name="username"
              placeholder={usersTrans.search.placeholders.username}
              label={usersTrans.search.labels.username}
            />

            <AntdFormSelectField
              name="company"
              placeholder={usersTrans.search.placeholders.company}
              label={usersTrans.search.labels.company}
              options={companies}
            />
          </AntdSearch>
        )}

        <section className="row p-3 mt-4 mx-2 section">
          {loading ? (
            <div className={"d-flex flex-fill justify-content-center p-4"}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="col-12 my-2 text-right">
                <Button
                  onClick={() => setShowFilters(!showFilters)}
                  className="mx-1 mr-2 d-inline-block"
                >
                  <i className="fa fa-filter" aria-hidden="true" />
                </Button>
                <Button type="primary" onClick={() => goToEdit()}>{commonTranslations.buttons.new}</Button>
              </div>

              <CustomGridAntd
                loading={tableLoading}
                dataSource={dataSource}
                columns={columns}
                total={totalRows}
                currentPage={currentPage}
                onTableChange={(pagination, filters, sorter: SorterResult<any>, extra) => {
                  const { columnKey, order } = sorter;
                  const { current, pageSize } = pagination;

                  setCurrentPage(current);
                  const size = pageSize || 10
                  setParams((currentParams) => ({
                    ...currentParams,
                    order_by: order ? columnKey as string : "",
                    sort_dir: order === "ascend" ? "asc" : "desc",
                    offset: (current - 1) * size,
                    limit: pageSize
                  }));
                }}
              />
            </>
          )}
        </section>
      </>
    </Layout>
  );
},
  { FallbackComponent: ErroredLayout }
);

export default Users;
